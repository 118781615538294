<template>
    <div></div>
</template>

<script>
import router from '@/router/index'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import $ from 'jquery';

export default{
    setup(){
        const myRoute = useRoute();
        const store = useStore();
        $.ajax({
            url:"https://www.suhj745.fun/api/user/qq/web/apply_code/",
            // url:"https://app6528.acapp.acwing.com.cn/api/user/account/qq/web/apply_code/",
            // url:"http://localhost:3000/api/user/account/qq/web/apply_code/",
            type: "GET",
            data: {
                code: myRoute.query.code,
                state: myRoute.query.state,
            },
            success: (resp) => {
                if(resp.result === "success"){
                    localStorage.setItem("jwt_token", resp.jwt_token);
                    store.commit("updateToken", resp.jwt_token);
                    router.push({name: 'home'});
                } else{
                    router.push({name: 'user_account_login'});
                }
            },

        });
        
        return{
            
        }
    }
}
</script>

<style scoped>
</style>