import { AcGameObject } from "./AcGameObject";
import { Cell } from "./Cell";

export class Snake extends AcGameObject{
    constructor(info, gamemap){
        super();

        this.id = info.id;
        this.color = info.color;
        this.gamemap = gamemap;

        this.cells = [new Cell(info.r, info.c)]; //body of snake ,the head is cells[0];
        this.next_cell = null; //next position

        this.speed = 5; //snake move 5 cells every second
        this.direction = -1; // -1 means no direction, 0/1/2/3 refers to up/right/down/left
        this.status = "idle"; // idle/move/die

        this.dr = [-1,0,1,0]; //rows offset of four directions
        this.dc = [0,1,0,-1]; //cols offset of four directions

        this.step = 0; //step count
        this.eps = 5e-2; // threshold to justify two point overlap
        
        this.eye_direction = 0;
        if(this.id === 1) this.eye_direction = 2; //if this is the second snake, eye's direction should towards down

        this.eye_dx = [
            [-1,1],
            [1,1],
            [1,-1],
            [-1,-1],
        ];  
        this.eye_dy = [
            [-1,-1],
            [-1,1],
            [1,1],
            [1,-1],
        ]; //offset of snake's eye in each direction
    }

    start(){

    }

    set_direction(d){
        this.direction = d;
    }

    check_tail_increasing(){ // check whether the snack will increase in this step
        if(this.step <= 10 ) return true;
        if(this.step % 3 === 1) return true;
        return false;
    }

    next_step(){ // change status of snake to 'going next step'
        const d = this.direction;
        this.next_cell = new Cell(this.cells[0].r + this.dr[d], this.cells[0].c + this.dc[d]);
        this.eye_direction = d;
        this.direction = -1; // clear direction
        this.status = "move";
        this.step++;

        const k = this.cells.length;
        for(let i = k; i>0; i--) {
            this.cells[i] = JSON.parse(JSON.stringify(this.cells[i-1]));
        }
    }

    update_move() {
        const dx = this.next_cell.x - this.cells[0].x;
        const dy = this.next_cell.y - this.cells[0].y;
        const distance = Math.sqrt(dx *dx + dy*dy);

        if(distance < this.eps){ // reach the destination
            this.cells[0] = this.next_cell; //add new head
            this.next_cell = null;
            this.status = "idle"; //stop moving

            if(!this.check_tail_increasing()){ //delete current tail if necessary
                this.cells.pop()
            }
        } else{
            const move_distance = this.speed * this.timedelta / 1000; //distance between frames
            this.cells[0].x += move_distance * dx / distance;
            this.cells[0].y += move_distance * dy / distance;

            if(!this.check_tail_increasing()) {
                const k = this.cells.length;
                const tail = this.cells[k-1],tail_target = this.cells[k - 2];
                const tail_dx = tail_target.x - tail.x;
                const tail_dy = tail_target.y - tail.y;
                tail.x += move_distance * tail_dx / distance;
                tail.y += move_distance * tail_dy / distance;
            }
        }
    }

    update(){
        if(this.status === "move"){
            this.update_move();
        }

        this.render();
    }

    render(){
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        ctx.fillStyle = this.color;
        if(this.status === "die"){
            ctx.fillStyle = "white"
        }

        for(const cell of this.cells){
            ctx.beginPath();
            ctx.arc(cell.x*L, cell.y*L, L/2 * 0.8, 0, Math.PI * 2);
            ctx.fill();
        }

        for(let i = 1; i<this.cells.length; i++){
            const a = this.cells[i-1], b = this.cells[i];
            if(Math.abs(a.x-b.x)<this.eps && Math.abs(a.y-b.y)<this.eps)
                continue;
            if(Math.abs(a.x - b.x) < this.eps){
                ctx.fillRect((a.x - 0.4) * L , Math.min(a.y,b.y) * L, L * 0.8, Math.abs(a.y - b.y) * L);
            } else {
                ctx.fillRect(Math.min(a.x , b.x) * L, (a.y-0.4) * L, Math.abs(a.x - b.x) * L, L * 0.8);
            }
        }

        ctx.fillStyle = "black";
        for(let i = 0; i<2; i++){ // left eye and right eye
            const eye_x = (this.cells[0].x + this.eye_dx[this.eye_direction][i] * 0.15) * L;
            const eye_y = (this.cells[0].y + this.eye_dy[this.eye_direction][i] * 0.15) * L;
            
            ctx.beginPath();
            ctx.arc(eye_x, eye_y, L*0.05, 0, Math.PI * 2);
            ctx.fill();
        }
    }
}