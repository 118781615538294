import $ from 'jquery'

export default{  
  state: {
    id: "",
    username:"",
    photo:"",
    token: "",
    is_login:false,
  },
  getters: {
  },
  mutations: {
    updateUser(state,user){
        state.id = user.id;
        state.username = user.username;
        state.photo= user.photo;
        state.is_login = user.is_login;
    },
    updateToken(state,token){
        state.token = token;
    },
    logout(state){
        state.id = "";
        state.username = "";
        state.photo = "";
        state.token = "";
        state.is_login = false;
    },
  },
  actions: {
    login(context,data){
        $.ajax({
            url:"https://www.suhj745.fun/api/user/account/token/",
            // url:"https://app6528.acapp.acwing.com.cn/api/user/account/token/",
            // url:"http://localhost:3000/api/user/account/token/", // for debug
            type: "post",
            data:{
              username: data.username,
              password: data.password,
            },
            success(resp){
                if(resp.error_message === "success"){
                    localStorage.setItem("jwt_token", resp.token);
                    context.commit("updateToken", resp.token);
                    data.success(resp);
                }
                else{
                    data.error(resp);
                }
            },
            error(resp){
              data.error(resp);
            }
          });     
    },
    getInfo(context, data){
        $.ajax({
            url:"https://www.suhj745.fun/api/user/account/info/",
            // url:"https://app6528.acapp.acwing.com.cn/api/user/account/info/",
            // url:"http://localhost:3000/api/user/account/info/",
            type:"get",
            headers: {
                Authorization : "Bearer "+ context.state.token,
            },
            success(resp){
                if(resp.error_message==="success"){
                    context.commit("updateUser",{
                    ...resp,
                    is_login : true,
                    });
                    data.success(resp);
                } else {
                    data.error(resp);
                }
            },
            error(resp){
                data.error(resp);
            },
            async: false,
        });
    },
    logout(context){
        localStorage.removeItem("jwt_token");
        context.commit("logout");
    }
  },
  modules: {
  }
}