<template>
    <ContentField>
        <div class="row justify-content-md-center">
            <div class="col-3">
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <div class="row">
                        <div class="col">
                            <button type="submit" class="btn btn-primary">登陆</button>
                        </div>
                    </div>
                </form>
                <!-- <div style="text-align: center; margin-top: 20px; cursor: pointer;" @click="acwing_login">
                    <img style="width: 30px;" src="https://cdn.acwing.com/media/article/image/2022/09/06/1_32f001fd2d-acwing_logo.png" alt="">
                    <br>
                    AcWing一键登录
                </div> -->
                <div style="text-align: center; margin-top: 20px; cursor: pointer;" @click="qq_login">
                    <img style="height: 35px;" src="@/assets/icons/Connect_logo_5.png" alt="">
                </div>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField.vue"
import { useStore } from "vuex";
import {ref} from 'vue'
import router from '@/router/index'
import $ from 'jquery'

export default{
    components:{
        ContentField
    },
    setup(){
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');

        const login = () => {
            error_message.value = "";
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success(){
                    store.dispatch("getInfo", {
                        success(){
                            router.push({name: 'home'});
                            console.log(store.state.user);
                        }
                    })
                },
                error(){
                    error_message.value = "用户名或密码错误";
                }
            })
        }

        const acwing_login = () => {
            $.ajax({
                url:"https://www.suhj745.fun/api/user/account/acwing/web/apply_code/",
                // url:"https://app6528.acapp.acwing.com.cn/api/user/account/acwing/web/apply_code/",
                // url:"http://localhost:3000/api/user/account/acwing/web/apply_code/",
                type: "GET",
                success: (resp) => {
                    if(resp.result === "success"){
                        window.location.replace(resp.apply_code_url);
                    }
                }
            })
        }

        const qq_login = () => {
            $.ajax({
                url:"https://www.suhj745.fun/api/user/account/qq/web/apply_code/",
                // url:"https://app6528.acapp.acwing.com.cn/api/user/account/qq/web/apply_code/",
                // url:"http://localhost:3000/api/user/account/qq/web/apply_code/",
                type: "GET",
                success: resp => {
                    if (resp.result === "success") {
                        window.location.replace(resp.apply_code_url);
                    }
                }
            })
        }

        return {
            username,
            password,
            error_message,
            login,
            acwing_login,
            qq_login,
        }
    }
}
</script>

<style scoped>
button{
    width: 100%;
}
div.error-message{
    color:red;
}
</style>